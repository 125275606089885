import { fetchSimpleStatisticsPage } from '@/api/DDJ/v3/postBack';
import { fetchCountryList } from '@/api/DDJ/v3/country';
import { fetchEMUList } from '@/api/DDJ/v3/emu';
import { getAdvertiserList } from '@/api/DDJ/v3/advertiser/index';
import optionData from '@/assets/js/optionData';
export default {
  components: {},
  data() {
    return {
      filter: {
        time: [],
      },
      options: {
        country: [],
        os: optionData.osOption,
        advertiser: [],
        searchAffType: [],
      },
      list: [
        {
          offerId: '1',
        },
      ],
      loading: {
        list: false,
      },
      pages: {
        pageNum: 1,
        pageSize: 20,
      },
      pagesTotal: 0,
      isShowPage: false,
      pickerOptions: {
        shortcuts: [
          {
            text: '今天',
            onClick(picker) {
              const start = new Date(new Date().toDateString());
              const end = new Date(
                new Date(new Date().toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000 - 1
              );
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            },
          },
        ],
      },
      rules: {},
    };
  },
  mounted() {
    const day2 = new Date();
    day2.setTime(day2.getTime());
    const s2 =
      day2.getFullYear() +
      '-' +
      (day2.getMonth() + 1 < 10 ? '0' + (day2.getMonth() + 1) : day2.getMonth() + 1) +
      '-' +
      day2.getDate();
    this.filter.time = [s2 + ' 00:00:00', s2 + ' 23:59:59'];
    this.getCountry();
    this.getEMU();
    this.advertiser();
    this.getList();
  },
  computed: {},
  methods: {
    getList(types) {
      // if (!this.filter.offerId && this.filter.time.length === 0) {
      //   this.$message.error('offer ID 或 日期 必须选择一项！');
      //   return;
      // }
      this.loading.list = true;
      if (types == 'filter') {
        this.pages.pageNum = 1;
        this.isShowPage = false;
      }
      let filterTem = Object.assign({}, this.filter);
      delete filterTem.time;
      if (this.filter.time !== null) {
        if (this.filter.time.length > 0) {
          filterTem.startTime = this.filter.time[0];
          filterTem.endTime = this.filter.time[1];
        }
      }
      let query = Object.assign(filterTem, this.pages);
      fetchSimpleStatisticsPage(query).then((res) => {
        let data = res.result;
        this.list = [];
        this.list = data.records;
        this.pagesTotal = data.total;
        this.loading.list = false;
        this.isShowPage = true;
      });
    },
    // 国家
    getCountry() {
      fetchCountryList().then((res) => {
        console.log(res);
        this.options.country = res.result;
      });
    },
    // aff
    getEMU() {
      fetchEMUList().then((res) => {
        console.log(res);
        this.options.searchAffType = res.result;
      });
    },
    advertiser() {
      getAdvertiserList().then((res) => {
        this.options.advertiser = res.result;
        console.log(res);
      });
    },
    // 分页监听
    handleCurrentChange(val) {
      this.pages.pageNum = val;
      this.getList();
    },
  },
};
