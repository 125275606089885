<template>
  <div class="ddj-simpleStatistics">
    <!-- <div class="main-Title bgff"><h2>简单统计</h2></div> -->
    <div class="mainBox">
      <el-card shadow="hover" class="list-filter mb10">
        <el-form :model="filter" label-width="80px" size="small">
          <el-row :gutter="10">
            <el-col :xs="24" :sm="12" :md="6" :xl="3">
              <el-form-item label="offer ID:"
                ><el-input
                  v-model="filter.offerId"
                  placeholder="Please enter offer ID"
                  class="mr10"
                ></el-input
              ></el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="6" :xl="3">
              <el-form-item label="Country:" label-width="60px">
                <el-select v-model="filter.country" placeholder="Please select" class="w100">
                  <el-option label="All" value="" />
                  <el-option
                    v-for="item in options.country"
                    :key="item.countryCode"
                    :value="item.countryCode"
                    :label="item.countryCode"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="6" :xl="3">
              <el-form-item label="aff:" label-width="30px">
                <el-select v-model="filter.affType" placeholder="Please select" class="w100">
                  <el-option label="All" value="" />
                  <el-option
                    v-for="item in options.searchAffType"
                    :key="item.id"
                    :value="item.id"
                    :label="item.emuName"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="6" :xl="3">
              <el-form-item label="OS:" label-width="30px">
                <el-select v-model="filter.os" placeholder="Please select OS" class="w100">
                  <el-option label="All" value="" />
                  <el-option
                    v-for="item in options.os"
                    :key="item.value"
                    :value="item.value"
                    :label="item.label"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="6" :xl="4">
              <el-form-item label="上游名称:">
                <el-select
                  v-model="filter.advertiserName"
                  placeholder="Please select Advertiser"
                  class="w100"
                >
                  <el-option label="All" value="" />
                  <el-option
                    v-for="item in options.advertiser"
                    :key="item.advertiserCode"
                    :value="item.advertiserName"
                    :label="item.advertiserName"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="6" :xl="6" style="height: 51px;">
              <el-form-item label="日期:" label-width="50px">
                <el-date-picker
                  v-model="filter.time"
                  type="datetimerange"
                  :picker-options="pickerOptions"
                  range-separator="-"
                  start-placeholder="Start Date"
                  end-placeholder="End Date"
                  class="w100"
                  align="right"
                  value-format="yyyy-MM-dd HH:mm:ss"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="6" :xl="2">
              <el-button
                type="primary"
                size="mini"
                @click="getList('filter')"
                :loading="loading.list"
                >Search</el-button
              >
            </el-col>
          </el-row>
        </el-form>
      </el-card>
      <el-card shadow="hover">
        <el-table
          :data="list"
          v-loading="loading.list"
          ref="listTableRef"
          class="w100"
          size="mini"
          border
          highlight-current-row
        >
          <el-table-column label="offer ID" prop="offerId"></el-table-column>
          <el-table-column label="上游名称" prop="advertiserName"></el-table-column>
          <el-table-column label="Country" prop="country"></el-table-column>
          <el-table-column label="OS" prop="os"></el-table-column>
          <el-table-column label="Price" prop="price"></el-table-column>
          <el-table-column label="成功点击" prop="clickCount"></el-table-column>
          <el-table-column label="转化数" prop="cvCount"></el-table-column>
          <el-table-column label="日期" prop="dateTime"></el-table-column>
          <el-table-column label="收益" prop="totalEarnings"></el-table-column>
        </el-table>
        <!-- 分页区域 -->
        <div v-if="isShowPage" class="pagination-container pt-10" align="center">
          <el-pagination
            :page-size="pages.pageSize"
            :total="pagesTotal"
            background
            layout="total, prev, pager, next, jumper"
            @current-change="handleCurrentChange"
          />
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
  import ddjSimpleStatisticsCon from '../../controllers/DDJ/v3/simpleStatistics';
  export default {
    name: 'simpleStatistics',
    ...ddjSimpleStatisticsCon,
  };
</script>

<style></style>
